import React from 'react';
import { Helmet } from 'react-helmet';

export default function HeadHelmet() {
	return (
		<Helmet>
			{/** Custom Head Metadata goes here */}
			<script type="text/javascript">
				{`
				console.log("%cJane is powerful.", "color: #0052cc; font-size: 40px");
				console.log("%cGot tech skills?", "color: green; font-size: 20px");
				console.log("%cSell & teach at:", "color: green; font-size: 20px");
				console.log("%c\\nhttps://www.hostjane.com/sell", "font-size: 20px");
				`}
			</script>
			<script
				data-ad-client="ca-pub-5489275624443011"
				async
				src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
			/>
		</Helmet>
	);
}
