import React, { createContext, useContext, useCallback, useState } from 'react';

const openGlobalModalContext = createContext();
const isGlobalModalActiveContext = createContext();

export function useOpenGlobalModal() {
	return useContext(openGlobalModalContext);
}

export function useIsGlobalModalActive() {
	return useContext(isGlobalModalActiveContext);
}

export function GlobalModalProvider({ setIsOpen, isActive, children }) {
	const openModal = useCallback(() => {
		setIsOpen(true);
	}, [setIsOpen]);
	return (
		<openGlobalModalContext.Provider value={openModal}>
			<isGlobalModalActiveContext.Provider value={isActive}>
				{children}
			</isGlobalModalActiveContext.Provider>
		</openGlobalModalContext.Provider>
	);
}

export function useModal() {
	const [open, setOpen] = useState(false);
	const closeModal = useCallback(() => {
		setOpen(false);
	}, []);
	const openModal = useCallback(() => {
		setOpen(true);
	}, []);
	return [open, openModal, closeModal];
}
