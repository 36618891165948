import React from 'react';
import styled from 'styled-components';
import listModalData from '../../../site-data/list-modal';

const ListModalWrapper = styled.div`
	&.hostjane-main-listModal {
		display: ${props => (props.enableModal ? 'block' : 'none')};
		position: absolute;
		background-color: rgba(255, 255, 255, 1);
		box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px,
			rgba(101, 119, 134, 0.15) 0px 0px 3px 1px;
		max-height: calc(100vh - 189px);
		left: 110.5px;
		bottom: 0;
		max-width: calc(295px);
		border-radius: 4px;
		// align-items: stretch;
		border: 0 solid black;
		.hostjane-main-listModal__main {
			.hostjane-main-listModal__main__wrapper {
				align-items: stretch;
				border: 0 solid black;
				box-sizing: border-box;
				.hostjane-main-listModal__main__wrapper__sub-wrapper {
					.hostjane-main-listModal__main__wrapper__sub-wrapper__link-wrapper {
						display: flex;
						flex-basis: auto;
						flex-direction: column;
						flex-shrink: 0;
						margin-bottom: 0px;
						margin-left: 0px;
						margin-right: 0px;
						margin-top: 0px;
						min-height: 0px;
						min-width: 0px;
						padding-bottom: 0px;
						padding-left: 0px;
						padding-right: 0px;
						padding-top: 0px;
						position: relative;
						// z-index: 0;
						.divider {
							background-color: rgb(230, 236, 240);
							height: 1px;
							margin-bottom: 2px;
							margin-top: 2px;
						}
					}
				}
			}
		}
	}
`;

const Link = styled.a`
	background-color: rgba(0, 0, 0, 0);
	color: inherit;
	text-align: inherit;
	font: inherit;
	list-style: none;
	text-decoration: none;
	max-width: 100%;
	flex-grow: 1;
	transition-property: background-color, box-shadow;
	transition-duration: 0.2s;
	min-width: 235px;
	&:hover {
		color: inherit;
		text-decoration: none;
		background-color: rgb(245, 248, 250);
	}
	.hostjane-main-listModal__main__wrapper__sub-wrapper__link-wrapper__link {
		padding: 15px;
		display: flex;
		align-items: center;
		// justify-content: space-between;
		svg {
			height: 18.75px;
			width: 18.75px;
			margin-right: 10px;
			fill: #657786;
		}
		.hostjane-main-listModal__main__wrapper__sub-wrapper__link-wrapper__link__content {
			span {
			}
		}
	}
`;

const ListModal = ({ enableModal }) => {
	return (
		<ListModalWrapper
			enableModal={enableModal}
			className="hostjane-main-listModal"
		>
			<div className="hostjane-main-listModal__main">
				<div className="hostjane-main-listModal__main__wrapper">
					<div className="hostjane-main-listModal__main__wrapper__sub-wrapper">
						<div className="hostjane-main-listModal__main__wrapper__sub-wrapper__link-wrapper">
							{listModalData.map(
								({ link, icon, href, divider }) => {
									return (
										<>
											{divider && (
												<div className="divider" />
											)}
											<Link href={href}>
												<div className="hostjane-main-listModal__main__wrapper__sub-wrapper__link-wrapper__link">
													{icon}
													<div className="hostjane-main-listModal__main__wrapper__sub-wrapper__link-wrapper__link__content">
														<span>{link}</span>
													</div>
												</div>
											</Link>
										</>
									);
								}
							)}
						</div>
					</div>
				</div>
			</div>
		</ListModalWrapper>
	);
};

export default ListModal;
