import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import GenericIcon from '../GenericIcon';
import { ReactComponent as CloseIcon } from '../../svgs/icons/times.svg';

ReactModal.setAppElement('#___gatsby');

export function Modal({ isOpen, closeModal, contentLabel, children }) {
	return (
		<ReactModal
			className="hostjane-modal"
			overlayClassName="hostjane-modal-overlay"
			isOpen={isOpen}
			onRequestClose={closeModal}
			contentLabel={contentLabel}
		>
			{children}
		</ReactModal>
	);
}

const ModalHeaderContainer = styled.header`
	padding: 15px 15px 0 15px;
	position: relative;
	color: #222;
	flex: 0 0 auto;
	.hostjane-modal-header__title {
		margin-top: 0;
		font-size: 25px;
		margin: 0 0 0;
		padding: 0 0 10px 0;
		border-bottom: 1px solid #f2f4f4;
		line-height: 1.6;
	}
	.hostjane-modal-header__close {
		background-color: transparent;
		border: 0 none;
		margin: 0;
		padding: 0;
		appearance: none;
		font-size: 18px;
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
		color: #333;
	}
`;
export function ModalHeader({ title, closeModal }) {
	return (
		<ModalHeaderContainer className="hostjane-modal-header">
			<h2 className="hostjane-modal-header__title">{title}</h2>
			<button
				className="hostjane-modal-header__close"
				onClick={closeModal}
				type="button"
			>
				<GenericIcon title="Close">
					<CloseIcon />
				</GenericIcon>
			</button>
		</ModalHeaderContainer>
	);
}

const ModalBodyContainer = styled.main`
	padding: 10px 15px 20px 15px;
	flex: 1 1 auto;
	overflow-y: auto;
`;
export function ModalBody({ children }) {
	return <ModalBodyContainer>{children}</ModalBodyContainer>;
}

const ModalFooterContainer = styled.footer`
	margin-top: auto;
	flex: 0 0 auto;
`;
export function ModalFooter({ children }) {
	return <ModalFooterContainer>{children}</ModalFooterContainer>;
}
