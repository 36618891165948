/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
	description,
	lang = 'en',
	meta = [],
	title,
	addPrefix = true,
	image = null,
}) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						author
						description
						social {
							twitter
						}
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	const helmetMeta = [...meta];
	const ogImage = image || '/hostjane_og.jpg';

	helmetMeta.push(
		{
			name: 'og:image',
			content: ogImage,
		},
		{
			name: 'twitter:card',
			content: 'summary_large_image',
		}
	);

	return (
		<Helmet
			defer={false}
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={addPrefix ? `%s - ${site.siteMetadata.title}` : '%s'}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					name: 'author',
					content: site.siteMetadata.author,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.social.twitter.author,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			].concat(helmetMeta)}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

export default SEO;
