import styled from 'styled-components';

const Banner = styled.div`
	background: ${props => props.theme.background};
	border-bottom: 1px solid ${props => props.theme.neutrals.lightOne};
	font-size: 16px;
	line-height: 1.5;
	color: ${props => props.theme.neutrals.secondaryText};
	margin: -${props => props.theme.gutter.small}px;
	margin-bottom: ${props => props.theme.gutter.small}px;
	padding: 8px 16px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	@media ${props => props.theme.breakpoints.tabletQuery} {
		margin: -${props => props.theme.gutter.large}px;
		margin-bottom: ${props => props.theme.gutter.large}px;
		padding: 16px 32px;
	}

	> div {
		max-width: ${props => props.theme.maxWidth}px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		width: 100%;
	}

	.news-label {
		padding-right: 8px;
		// color: ${props => props.theme.supporting.red};
		color: #007bff;
		font-weight: 800;
		text-transform: uppercase;
	}

	.banner-link {
		font-weight: 400;
		margin: 0 -8px 0 8px;
		transition: background 0.2s, box-shadow 0.2s, color 0.2s;
		background: 0;
		padding: 0 8px;
		line-height: 36px;
		color: #1a73e8;
		border-radius: 2px;
		border: 0;
		height: 36px;
		min-width: 36px;
		outline: 0;
		overflow: hidden;
		text-decoration: none;
		text-overflow: ellipsis;
		box-sizing: border-box;
		cursor: pointer;
		vertical-align: middle;
		white-space: nowrap;
		background: #e4eefc;
		&:hover {
			color: #1a73e8;
		}
	}
`;

export default Banner;
