const colors = {
	primary: {
		darkest: '#19216C',
		dark: '#35469C',
		light: '#4055A8',
		lightest: '#4C63B6',
		border: '#BED0F7',
		background: '#F2F5FD',
	},
	neutrals: {
		darkest: '#1F2933',
		darker: '#3E4C59',
		secondaryText: '#222222',
		light: '#7B8794',
		lightOne: '#CBD2D9',
		lightTwo: '#E4E7EB',
		lightest: '#F5F7FA',
	},
	supporting: {
		success: '#199473',
		red: '#e41937',
		icon: '#0052CC',
	},
	background: '#FFFFFF',
	breakpoints: {
		tablet: '768px',
		tabletQuery: 'screen and (min-width: 768px)',
		desktop: '1024px',
		desktopQuery: 'screen and (min-width: 1024px)',
		desktopHd: '1440px',
		desktopHdQuery: 'screen and (min-width: 1440px)',
	},
	gutter: {
		small: 20,
		large: 40,
	},
	effects: {
		easeOutBack: 'cubic-bezier(0.175, 0.885, 0.375, 1.095)',
		easeOutSine: 'cubic-bezier(0.390, 0.575, 0.565, 1.000)',
	},
	maxWidth: 1200,
	siteApp: {
		maxWidth: 1440,
		transition: '200ms ease-out',
		navHeight: {
			mobile: 45,
			tablet: 60,
			desktop: 85,
		},
		navFontSize: 16,
		navColor: '#222222',
		navAnchorColor: '#222222',
		navAnchorHoverColor: '#595959',
		desktopNavBorderColor: '#e1e1e1',
		navSearch: {
			height: 34,
			color: '#495057',
			borderColor: '#ced4da',
		},
		primaryColor: '#e41937',
		primaryHoverColor: '#ef1132',
		primaryDarkColor: '#98012E',
		tapBackground: 'rgba(228, 25, 55, 0.1)',
		mutedColor: '#868e96',
		accentColor: '#1473e6',
		accentDarkerColor: '#005abe',
		accentBoxShadowColor: 'rgba(0, 123, 255, 0.25)',
		fz: {
			h1: 96,
			h2: 60,
			h3: 48,
			h4: 34,
			h5: 24,
			h6: 20,
			subtitle1: 18,
			subtitle2: 16,
			body: 18,
		},
		breakpoints: {
			tablet: 768,
			desktop: 1330,
		},
	},
};

export default colors;
