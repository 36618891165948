import React, { useState, useCallback, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import ResizeObserver from 'resize-observer-polyfill';
import useMeasure from 'react-use-measure';
import { useStaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Helmet } from 'react-helmet';

import theme from '../../utils/theme';
import SiteNav from '../SiteNav';
import GlobalStyle from '../../utils/globalStyle';
import Container from '../Container';
import Banner from '../Banner';
import { BoundsProvider } from '../../utils/breakpoints';
import { TwitterBlock } from '../StyledContents';
import { GlobalModalProvider } from '../../utils/globalModal';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '../Modal';

import HeadHelmet from '../../../site-data/head';
import DropdownButton from '../AppForm/DropdownButton';

const LayoutWrap = styled.div`
	@media ${props => props.theme.breakpoints.tabletQuery} {
		display: grid;
		grid-template-columns: 110px calc(100% - 110px);
		min-height: 100vh;
	}
`;

const MainWrap = styled.main`
	padding: ${props => (props.fullWidth ? 0 : props.theme.gutter.small)}px;
	padding-bottom: 84px; /* 64px for nav menu and 20px gutter */
	@media ${props => props.theme.breakpoints.tabletQuery} {
		min-height: 100vh;
		padding: ${props => (props.fullWidth ? 0 : props.theme.gutter.large)}px;
		padding-bottom: ${props =>
			props.hasFooter
				? '120px'
				: '40px'}; /* 80px for footer and 40px gutter */
	}
`;

export default function Layout(props) {
	const {
		children,
		banner = null,
		hasFooter = true,
		fullWidth = false,
		altNav = false,
	} = props;

	const [ref, bounds] = useMeasure({
		debounce: 200,
		polyfill: ResizeObserver,
	});

	const [isModalOpen, setIsModalOpen] = useState(false);
	const closeModal = useCallback(() => {
		setIsModalOpen(false);
	}, []);

	const modalData = useStaticQuery(graphql`
		query GlobalModal {
			globalModal: mdx(
				fileAbsolutePath: { glob: "**/site-data/modals/global.mdx" }
			) {
				frontmatter {
					title
					active
					twitter
				}
				body
			}
		}
	`);
	const isGlobalActive = modalData.globalModal.frontmatter.active;

	useEffect(() => {
		const handler = e => {
			let anchor;
			if (e.target.tagName === 'A') {
				anchor = e.target;
			} else {
				anchor = e.target.closest('a');
			}

			if (anchor && isGlobalActive) {
				const href = anchor.getAttribute('href');
				if (
					href &&
					href.includes('/marketplace') &&
					!href.includes('/marketplace-')
				) {
					e.preventDefault();
					e.stopPropagation();
					setIsModalOpen(true);
				}
			}
		};
		document.addEventListener('click', handler);
		return () => document.removeEventListener('click', handler);
	}, [isGlobalActive]);

	return (
		<ThemeProvider theme={theme}>
			<BoundsProvider bounds={bounds}>
				<GlobalModalProvider
					setIsOpen={setIsModalOpen}
					isActive={isGlobalActive}
				>
					<GlobalStyle />
					<Helmet defer={false}>
						<link
							href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap"
							rel="stylesheet"
						/>
					</Helmet>
					<HeadHelmet />
					<LayoutWrap ref={ref}>
						<SiteNav altNav={altNav} />
						<MainWrap
							id="hostjane-main"
							hasFooter={hasFooter}
							fullWidth={fullWidth}
						>
							{banner !== '' && banner !== null ? (
								<Banner>
									<div>
										<div
											dangerouslySetInnerHTML={{
												__html: banner,
											}}
										/>
										{!props.noBtnDropdown && (
											<>
												{' '}
												<DropdownButton />
												<a
													href="https://www.hostjane.com/marketplace/contact"
													className="banner-link"
												>
													Contact Support
												</a>
											</>
										)}
									</div>
								</Banner>
							) : null}
							<Container fullWidth={fullWidth}>
								{children}
							</Container>
						</MainWrap>
					</LayoutWrap>
					<Modal
						isOpen={isModalOpen}
						closeModal={closeModal}
						contentLabel={modalData.globalModal.frontmatter.title}
					>
						<ModalHeader
							title={modalData.globalModal.frontmatter.title}
							closeModal={closeModal}
						/>
						<ModalBody>
							<MDXRenderer>
								{modalData.globalModal.body}
							</MDXRenderer>
						</ModalBody>
						<ModalFooter>
							<TwitterBlock
								profile={
									modalData.globalModal.frontmatter.twitter
								}
							/>
						</ModalFooter>
					</Modal>
				</GlobalModalProvider>
			</BoundsProvider>
		</ThemeProvider>
	);
}
