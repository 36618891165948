import React, { createContext, useContext } from 'react';
import theme from './theme';

export const boundContext = createContext(null);

export function useBounds() {
	return useContext(boundContext);
}

export function useIsTablet() {
	const bounds = useBounds();
	return bounds.width > theme.siteApp.breakpoints.tablet - 1;
}

export function useIsDesktop() {
	const bounds = useBounds();
	return bounds.width > theme.siteApp.breakpoints.desktop - 1;
}

export function BoundsProvider({ bounds, children }) {
	return (
		<boundContext.Provider value={bounds}>{children}</boundContext.Provider>
	);
}
